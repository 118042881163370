<template>
  <moe-page title="关联设备">
    <moe-card class="mb-20" :autoHeight="true">
      <template slot="header">
        <div class="df aic jcsb">
          <div class="df fdc">
            <div class="font-20 fwb">关联设备</div>
          </div>
        </div>
      </template>

      <moe-inquire @search="equipmentSearch">
        <el-form-item label="商品类型">
          <moe-select type="goodsEquipmentList" v-model="equipmentParams.goodsType" placeholder="选择商品类型" @change="(value) => changeGoodsType(value)"></moe-select>
        </el-form-item>
      </moe-inquire>

      <moe-table
        ref="equipmentTableRef"
        :mode="equipmentParams.goodsType.length ? true : false"
        url="/deviceModel/paging"
        :params="equipmentParams"
        :numberShow="false"
        :showPage="false"
        rowKey="id"
        @selectChange="selectChange"
        :defaultSelectIds="deviceModelIds && deviceModelIds.length ? deviceModelIds.map(({ id }) => id) : []">
        <el-table-column type="selection" reserve-selection label="id" width="60" />
        <el-table-column prop="name" label="设备名称" min-width="130" />
        <el-table-column prop="productModel" label="设备型号" min-width="130" />
        <el-table-column prop="wireless" label="无线类型" width="80">
          <template slot-scope="{ row }">
            {{ wirelessComputed[row.wireless] }}
          </template>
        </el-table-column>
        <el-table-column prop="enable" label="状态" width="80">
          <template slot-scope="{ row }">
            {{ row.enable ? '启用' : '禁用' }}
          </template>
        </el-table-column>
        <el-table-column prop="empty" label="设备图片" width="130">
          <template slot-scope="{ row }">
            <moe-image :src="row.homeImage" width="100px" height="50px" />
          </template>
        </el-table-column>
        <el-table-column prop="empty" label="通用图片" width="130">
          <template slot-scope="{ row }">
            <moe-image :src="row.image" width="100px" height="50px" />
          </template>
        </el-table-column>
      </moe-table>
      <div class="df aic jcc mt-30">
        <el-button type="info" icon="el-icon-close" @click="$moe_coordinator.navigateBack()">关闭</el-button>
        <el-button type="primary" icon="el-icon-upload2" :loading="goodsDeviceLoad" @click="handleSubmit()">{{ goodsDeviceLoad ? '保存中' : '提交' }}</el-button>
      </div>
    </moe-card>
  </moe-page>
</template>

<script>
export default {
  name: 'GoodsDevice',
  computed: {
    wirelessComputed() {
      let options = {}
      if (!this.wirelessList) {
        return '-'
      }

      for (let key in this.wirelessList) {
        options[this.wirelessList[key]] = key
      }

      return options
    }
  },
  data() {
    return {
      equipmentParams: {
        pageNum: 1, //当前页
        pageSize: 999, //每页条数
        goodsType: '',
      },
      equipmentData: {
        goodsType: '',
        deviceModelIds: [],
      },
      wirelessList: [],
      goodsDeviceLoad: false,
      deviceModelIds: [],
    }
  },
  methods: {
    equipmentSearch(isSearch) {
      if (!isSearch) {
        this.equipmentParams = {
          pageNum: 1, //当前页
          pageSize: 999, //每页条数
          goodsType: '',
        };
      }

      this.$refs['equipmentTableRef'].searchData();
    },
    changeGoodsType(value) {
      const { goodsType, deviceModelIds } = this.equipmentData;
      if (value === goodsType) {
        this.deviceModelIds = this.$moe_lodash.cloneDeepData(deviceModelIds);
      } else {
        this.deviceModelIds.splice(0);
      }
      this.equipmentSearch(true);
    },
    selectChange(list) {
      this.deviceModelIds = list;
    },
    handleSubmit() {
      const { goodsType } = this.equipmentParams;
      if (!goodsType) {
        return this.$moe_msg.warning('请选择商品类型');
      }
      let params = {
        id: this.$route.query.id,
        goodsType,
        deviceModelIds: this.deviceModelIds && this.deviceModelIds.length ? this.deviceModelIds.map(({ id }) => id) : [],
      }
      this.req_relationDeviceModel(params);
    },
    /** 关联设备型号 */
    async req_relationDeviceModel(params) {
      const { code, message } = await this.$moe_api.EQUIPMENT_API.relationDeviceModel(params);
      if (code === 200) {
        this.$moe_msg.success('关联设备成功', {
          completionHandler: () => {
            this.$moe_coordinator.navigateBack();
          }
        });
      } else {
        this.$moe_msg.error(message);
      }
    },
    /** 获取关联设备型号详情 */
    async req_relationDeviceModelDetail() {
      if (this.$route.query.id) {
        const { code, result, message } = await this.$moe_api.EQUIPMENT_API.relationDeviceModelDetail({ id: this.$route.query.id });
        if (code === 200) {
          const { goodsType, deviceModelIds } = result
          if (goodsType) {
            this.equipmentData.goodsType = goodsType;
            this.equipmentParams.goodsType = goodsType;
          }
          if (deviceModelIds) {
            this.equipmentData.deviceModelIds = deviceModelIds.map((id) => {
              return {
                id
              }
            });
            this.deviceModelIds = this.$moe_lodash.cloneDeepData(this.equipmentData.deviceModelIds);
            this.equipmentSearch(true);
          }
        } else {
          this.$moe_msg.error(message);
        }
      }
    },
    /** 获取可用参数 */
    async req_getDeviceModelParams() {
      const { code, result, message } = await this.$moe_api.EQUIPMENT_API.getDeviceModelParams();
      if (code === 200) {
        this.wirelessList = result.wireless;
      } else {
        this.$moe_msg.error(message);
      }
    },
  },
  mounted() {
    this.req_getDeviceModelParams();
    this.req_relationDeviceModelDetail();
  },
}
</script>